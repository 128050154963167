<template>
  <div
    id="layout"
    class="antialiased bg-white text-stone-900 font-sans border-b flex flex-col min-h-screen"
  >
    <client-only>
      <NuxtLazyHydrate when-idle>
        <DateRequest />
      </NuxtLazyHydrate>

      <NuxtLazyHydrate when-visible>
        <EventSubscribeToUpdates />
      </NuxtLazyHydrate>
    </client-only>
    <PartnerNavbar
      :logo-img="partnerDataComputed?.logo"
      :extra-link-items="[
        {
          text: 'general.supplierProfileButton',
          path: `/p/${partnerDataComputed?.permalink}/`,
          showOnSub: true,
        },
      ]"
    />
    <header>
      <slot name="banner" />
    </header>

    <div class="max-w-screen-xl w-full m-auto px-4">
      <main>
        <slot />
      </main>
    </div>
    <slot name="footer">
      <ErrorBoundary>
        <Footer
          :footer-block-items="footerBlocks"
          :display-newsletter-subscription-form="false"
        />
      </ErrorBoundary>
    </slot>
  </div>
</template>

<script setup>
const subdom = useSubdomain()
const { locale, t: $t, baseUrl } = useI18n()
const localePath = useLocalePath()
const { subdomainData: partnerData, loadSubdomainData } =
  await useSubdomainData(subdom.value)

const { data: footerData } = useStrapiSingleType(`booking-solution-footer`, {
  query: { locale, 'populate[footerBlock][populate]': '*' },
})
const partnerDataComputed = computed(() => {
  /* if partner has no logo default domain will be shown */
  return {
    logo: supplierGetters.getHeaderLogo(partnerData.value) || undefined,
    permalink: supplierGetters.getPermalink(partnerData.value),
  }
})

const footerBlocks = computed(() => {
  const { footerBlock = [] } = footerData?.value?.data?.attributes || {}
  if (partnerData.value) {
    const phone = partnerData.value?.phone
    const email = partnerData.value?.email
    const address = partnerData.value?.address
    const website = partnerData.value?.website
    footerBlock[0].items = [
      ...(phone ? [{ id: 0, label: phone, link: `tel:${phone}` }] : []),
      ...(email ? [{ id: 1, label: email, link: `mailto:${email}` }] : []),
      ...(address ? [{ id: 2, label: address }] : []),
      ...(website ? [{ id: 3, label: website, link: website }] : []),
    ]
  }
  if (footerBlock[2]) {
    footerBlock[2].items = [
      {
        label: $t('general.titles.home') + $t('general.titles.base'),
        imgUrl: '/_nuxt3-static/logos/full-logo-color-white.png',
        link: partnerData.value
          ? baseUrl.value + localePath(`/p/${partnerData.value.permalink}`)
          : localePath('/'),
      },
    ]
  }
  return [
    ...footerBlock,
    {
      id: 23,
      title: 'Zahlungsarten',
      items: [
        {
          id: 226,
          label: 'Mastercard',
          link: null,
          imgUrl: 'https://gokonfetti.com/media/svg/card-logos/mastercard.svg',
        },
        {
          id: 227,
          label: 'Visa',
          link: null,
          imgUrl: 'https://gokonfetti.com/media/svg/card-logos/visa.svg',
        },
        {
          id: 230,
          label: 'Paypal',
          link: null,
          imgUrl: 'https://gokonfetti.com/media/svg/card-logos/paypal.svg',
        },
        {
          id: 231,
          label: 'Sepa',
          link: null,
          imgUrl: 'https://gokonfetti.com/payment-methods/sepa.svg',
        },
      ],
    },
    {
      id: 24,
      title: 'Folge uns auf',
      items: [
        {
          id: 232,
          label: 'facebook',
          link: 'https://www.facebook.com/gokonfetti',
          imgUrl: 'https://gokonfetti.com/icons/facebook-light.svg',
        },
        {
          id: 233,
          label: 'LinkedIn',
          link: 'https://www.linkedin.com/company/72680824',
          imgUrl: 'https://gokonfetti.com/icons/linkedin-light.svg',
        },
        {
          id: 234,
          label: 'Instagram',
          link: 'https://www.instagram.com/gokonfetti/',
          imgUrl: 'https://gokonfetti.com/icons/instagram-light.svg',
        },
      ],
    },
  ]
})

onMounted(async () => {
  const { useGtmPageView } = await import('~/composables/useGtmPageView')
  useGtmPageView()
})

useLayoutHead()
</script>
<style lang="postcss" scoped>
#layout {
  box-sizing: border-box;
}
</style>
